// Set the date we're counting down to
var countDownDate = new Date("2024-01-01T00:00:00.000-05:00").getTime();

function update(){
  var now = new Date().getTime();
  var elem = document.getElementById("countdown");

  // Find the distance between now and the count down date
  var distance = countDownDate - now;
  if (distance >= 0){
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    elem.innerHTML = days + "d " + hours + "h "
    + minutes + "m " + seconds + "s ";
    // If the count down is finished, write some text
    elem.classList.remove("ticker")
    elem.offsetWidth
    elem.classList.add("ticker")
  } else {
    clearInterval(x);
    elem.innerHTML = "GET FUCKED, DISNEY";
  }
}

var x = setInterval(update, 1000);
update();